// Copyright © 2023 The Things Industries B.V.

import tts from '@console/api/tts'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'

import * as noc from '@console/store/actions/network-operations-center.tti'

const getNocConfigurationLogic = createRequestLogic({
  type: noc.GET_NOC_CONFIGURATION,
  process: async () => tts.Noc.getConfiguration(),
})

const getNocConnectedGatewaysLogic = createRequestLogic({
  type: noc.GET_NOC_CONNECTED_GATEWAYS,
  process: async ({ action }) => {
    const { from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getConnectedGateways(from, to, bucketInterval)

    return data.daily_buckets
  },
})

const getNocActiveApplicationsLogic = createRequestLogic({
  type: noc.GET_NOC_ACTIVE_APPLICATIONS,
  process: async ({ action }) => {
    const { from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getActiveApplications(from, to, bucketInterval)

    return data.daily_buckets
  },
})

const getNocActiveEndDevicesLogic = createRequestLogic({
  type: noc.GET_NOC_ACTIVE_END_DEVICES,
  process: async ({ action }) => {
    const { from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getActiveEndDevices(from, to, bucketInterval)

    return data.daily_buckets
  },
})

const getNocGatewayUptimeLogic = createRequestLogic({
  type: noc.GET_NOC_GATEWAY_UPTIME,
  process: async ({ action }) => {
    const { gatewayId, from, to } = action.payload

    const data = await tts.Noc.getGatewayUptime(gatewayId, from, to)

    return { gatewayId, uptime: data }
  },
})

const getGatewayPacketsPerDataRateLogic = createRequestLogic({
  type: noc.GET_GATEWAY_PACKETS_PER_DATA_RATE,
  process: async ({ action }) => {
    const { gatewayId, from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getGatewayPacketsPerDataRate(gatewayId, from, to, bucketInterval)

    return data?.data_rates ?? []
  },
})

const getGatewayPacketsPerChannelLogic = createRequestLogic({
  type: noc.GET_GATEWAY_PACKETS_PER_CHANNEL,
  process: async ({ action }) => {
    const { gatewayId, from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getGatewayPacketsPerChannel(gatewayId, from, to, bucketInterval)

    return data?.channels ?? []
  },
})

const getApplicationPacketsPerDataRateLogic = createRequestLogic({
  type: noc.GET_APPLICATION_PACKETS_PER_DATA_RATE,
  process: async ({ action }) => {
    const { applicationId, from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getApplicationPacketsPerDataRate(
      applicationId,
      from,
      to,
      bucketInterval,
    )

    return data?.data_rates ?? []
  },
})

const getApplicationPacketsPerChannelLogic = createRequestLogic({
  type: noc.GET_APPLICATION_PACKETS_PER_CHANNEL,
  process: async ({ action }) => {
    const { applicationId, from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getApplicationPacketsPerChannel(
      applicationId,
      from,
      to,
      bucketInterval,
    )

    return data?.channels ?? []
  },
})

const getEndDevicePacketsPerDataRateLogic = createRequestLogic({
  type: noc.GET_END_DEVICE_PACKETS_PER_DATA_RATE,
  process: async ({ action }) => {
    const { applicationId, deviceId, from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getEndDevicePacketsPerDataRate(
      applicationId,
      deviceId,
      from,
      to,
      bucketInterval,
    )

    return data?.data_rates ?? []
  },
})

const getEndDevicePacketsPerChannelLogic = createRequestLogic({
  type: noc.GET_END_DEVICE_PACKETS_PER_CHANNEL,
  process: async ({ action }) => {
    const { applicationId, deviceId, from, to, bucketInterval } = action.payload

    const data = await tts.Noc.getEndDevicePacketsPerChannel(
      applicationId,
      deviceId,
      from,
      to,
      bucketInterval,
    )

    return data?.channels ?? []
  },
})

export default [
  getNocConfigurationLogic,
  getNocConnectedGatewaysLogic,
  getNocActiveApplicationsLogic,
  getNocActiveEndDevicesLogic,
  getNocGatewayUptimeLogic,
  getGatewayPacketsPerDataRateLogic,
  getGatewayPacketsPerChannelLogic,
  getApplicationPacketsPerDataRateLogic,
  getApplicationPacketsPerChannelLogic,
  getEndDevicePacketsPerDataRateLogic,
  getEndDevicePacketsPerChannelLogic,
]
