// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { APPLICATION, END_DEVICE, GATEWAY } from '@console/constants/entities'

import { IconApplication, IconDevice, IconGateway } from '@ttn-lw/components/icon'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import NocMetricsPanel from '@console/containers/noc-metrics-panel/index.tti'

import { subtractDays } from '@ttn-lw/lib/date-utils'
import { selectNocEnabled, selectNocUrl } from '@ttn-lw/lib/selectors/env'

import {
  getNocActiveApplications,
  getNocActiveEndDevices,
  getNocConnectedGateways,
} from '@console/store/actions/network-operations-center.tti'
import { getRegistryTotals } from '@console/store/actions/tenant.tti'

import {
  selectNocActiveApplications,
  selectNocActiveEndDevices,
  selectNocConnectedGateways,
} from '@console/store/selectors/network-operations-center.tti'
import { selectRegistryTotals } from '@console/store/selectors/tenant.tti'
import { selectUserIsAdmin } from '@console/store/selectors/user'

const m = defineMessages({
  activeApplications: 'Active applications',
  connectedGateways: 'Connected gateways',
  activeDevices: 'Active devices',
})

const NocPanels = ({ showRandomValues }) => {
  const registryTotals = useSelector(selectRegistryTotals)
  const nocUrl = selectNocUrl()
  const nocEnabled = selectNocEnabled()
  const isAdmin = useSelector(selectUserIsAdmin)
  const totalGateways = !showRandomValues ? registryTotals?.gateways ?? 0 : 10
  const totalApplications = !showRandomValues ? registryTotals?.applications ?? 0 : 100
  const totalEndDevices = !showRandomValues ? registryTotals?.end_devices ?? 0 : 1000

  const connectedGatewaysData = useSelector(selectNocConnectedGateways)
  const activeApplicationsData = useSelector(selectNocActiveApplications)
  const activeEndDevicesData = useSelector(selectNocActiveEndDevices)

  const from = subtractDays(new Date(), 30).toISOString()
  const to = new Date().toISOString()
  const bucketInterval = '300s'

  const requestActions = []

  // The redering of the panels is based on the user's permissions and the availability of the NOC.
  // Note the different setups:
  // 1. isAdmin
  //   a. nocEnabled - show panel on gateway overview
  //   b. nocEnabled && nocExtended - show panel for apps and devices
  //   c. !nocEnabled - blurry panel
  // 2. !isAdmin && sandbox - blurryPanel
  // 3. all other cases - nothing

  if ((!isAdmin || !nocEnabled || !Boolean(nocUrl)) && !showRandomValues) {
    return null
  }

  if (!showRandomValues) {
    requestActions.push(getRegistryTotals())
  }

  return (
    <RequireRequest requestAction={requestActions} handleErrors={false}>
      <div className="item-12 md-lg:item-4">
        <NocMetricsPanel
          title={m.activeApplications}
          icon={IconApplication}
          fetchData={() => getNocActiveApplications(from, to, bucketInterval)}
          data={activeApplicationsData}
          totalCount={totalApplications}
          showRandomValues={showRandomValues}
          entity={APPLICATION}
          entityPath={'/applications'}
        />
      </div>
      <div className="item-12 md-lg:item-4">
        <NocMetricsPanel
          title={m.connectedGateways}
          icon={IconGateway}
          fetchData={() => getNocConnectedGateways(from, to, bucketInterval)}
          data={connectedGatewaysData}
          totalCount={totalGateways}
          showRandomValues={showRandomValues}
          entity={GATEWAY}
          entityPath={'/gateways'}
        />
      </div>
      <div className="item-12 md-lg:item-4">
        <NocMetricsPanel
          title={m.activeDevices}
          icon={IconDevice}
          fetchData={() => getNocActiveEndDevices(from, to, bucketInterval)}
          data={activeEndDevicesData}
          totalCount={totalEndDevices}
          showRandomValues={showRandomValues}
          entity={END_DEVICE.split('_')[1]}
        />
      </div>
    </RequireRequest>
  )
}

NocPanels.propTypes = {
  showRandomValues: PropTypes.bool,
}

NocPanels.defaultProps = {
  showRandomValues: false,
}

export default NocPanels
