// Copyright © 2023 The Things Industries B.V.

import { createSelector } from 'reselect'

import { createFetchingSelector } from '@ttn-lw/lib/store/selectors/fetching'
import { createErrorSelector } from '@ttn-lw/lib/store/selectors/error'

import { GET_NOC_CONFIGURATION_BASE } from '@console/store/actions/network-operations-center.tti'

const selectNocStore = state => state.noc

export const selectNocConfiguration = state => selectNocStore(state).configuration
export const selectNocConfigurationFetching = createFetchingSelector(GET_NOC_CONFIGURATION_BASE)
export const selectNocConfigurationError = createErrorSelector(GET_NOC_CONFIGURATION_BASE)

export const selectNocAccessConfiguration = state => selectNocConfiguration(state).access || {}
export const selectNocExtendedAccess = state =>
  selectNocAccessConfiguration(state).extended || false

export const selectNocConnectedGateways = state => selectNocStore(state).connectedGateways

export const selectNocActiveApplications = state => selectNocStore(state).activeApplications

export const selectNocActiveEndDevices = state => selectNocStore(state).activeEndDevices

export const selectGatewayPacketsPerDataRate = state =>
  selectNocStore(state).gatewayPacketsPerDataRate

export const selectGatewayPacketsPerChannel = state =>
  selectNocStore(state).gatewayPacketsPerChannel

export const selectApplicationPacketsPerDataRate = state =>
  selectNocStore(state).applicationPacketsPerDataRate

export const selectApplicationPacketsPerChannel = state =>
  selectNocStore(state).applicationPacketsPerChannel

export const selectEndDevicePacketsPerDataRate = state =>
  selectNocStore(state).endDevicePacketsPerDataRate

export const selectEndDevicePacketsPerChannel = state =>
  selectNocStore(state).endDevicePacketsPerChannel

export const selectNocGatewayUptimeById = id =>
  createSelector([selectNocStore], store => {
    const uptime = store.uptime.find(u => Object.keys(u)[0] === id)
    return uptime ? uptime[id] : null
  })
