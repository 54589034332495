// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { useSelector } from 'react-redux'
import { defineMessages } from 'react-intl'

import Link from '@ttn-lw/components/link'
import Icon, { IconBolt, IconInfoCircle } from '@ttn-lw/components/icon'
import Tooltip from '@ttn-lw/components/tooltip'
import Button from '@ttn-lw/components/button'
import ProgressBar from '@ttn-lw/components/progress-bar'

import Message from '@ttn-lw/lib/components/message'

import { calculatePercentage } from '@console/containers/network-information-container/utils.tti'

import { selectPluginTTSCloud } from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { selectSubscriptionProduct } from '@console/store/selectors/subscription-management.tti'
import { selectOwnTenant, selectRegistryTotals } from '@console/store/selectors/tenant.tti'

import style from './side-footer.styl'

const m = defineMessages({
  currentEndDevices: '{totalEndDevices} of {threshold} devices',
  currentTierEndDevices: '{totalEndDevices} of {threshold} devices in this tier',
  currentGateways: '{totalGateways} of {threshold} gateways',
  discovery: 'The Things Stack Cloud Discovery',
  standard: 'The Things Stack Cloud Standard',
  plus: 'The Things Stack Cloud Plus',
  docker: 'The Things Stack Enterprise Docker',
  awsEcs: 'The Things Stack Enterprise AWS ECS',
  tiersTooltip:
    'You are using a subscription with device tiers. The chart shows the consumption of the current tier. If you exceed the device count, you will automatically move to the next pricing tier. Please see the <Link>admin panel</Link> for more information.',
  discoveryDevicesTooltip:
    'As Cloud Discovery user, the number of end devices you can register is limited to 10. Please upgrade to register unlimited end devices.',
  discoveryGatewaysTooltip:
    'As Cloud Discovery user, the number of gateways you can register is limited to 10. Please upgrade to register unlimited gateways.',
})

const SubscriptionNudge = () => {
  const subscriptionInfo = useSelector(selectSubscriptionProduct)
  const subscription = subscriptionInfo && subscriptionInfo.product.symbol
  const isDiscovery = subscription === 'DISCOVERY'
  const isPlus = subscription === 'PLUS'
  const isManualBilling = subscriptionInfo && subscriptionInfo.product.symbol === 'MANUAL-BILLING'
  const registryTotals = useSelector(selectRegistryTotals)
  const currentEndDevices = registryTotals?.end_devices ?? 0
  const currentGateways = registryTotals?.gateways ?? 0
  const metadata = subscriptionInfo?.price?.metadata || {}
  const discoveryTargetEndDevices = parseInt(metadata['max-end-devices'])
  const discoveryTargetGateways = parseInt(metadata['max-gateways'])
  const tiers = !isDiscovery ? subscriptionInfo?.price?.tiers : []
  const packageThreshold =
    !isDiscovery && Boolean(tiers)
      ? tiers.find(tier => tier.up_to >= currentEndDevices).up_to
      : null
  const smUrl = selectPluginTTSCloud().subscription_management_url
  const plansPageUrl = selectPluginTTSCloud().plans_page_url
  const ownTenant = useSelector(selectOwnTenant)
  const tenantStripe = ownTenant?.billing?.stripe
  const subscriptionId = tenantStripe?.subscription_id
  let upgradeUrl = plansPageUrl
  if (subscriptionId) {
    upgradeUrl = `${smUrl}/dashboard/subscriptions/${subscriptionId}/upgrade-cloud`
  }

  if (!subscriptionInfo || isManualBilling) {
    return null
  }

  return (
    <div className={style.sideFooterNudge}>
      <Message content={m[subscription.toLowerCase()]} className="fw-bold fs-m" />
      {isDiscovery ? (
        <>
          <div className="d-flex gap-cs-m">
            <div className="w-full">
              <ProgressBar
                className="mt-cs-l mb-cs-s"
                barClassName={style.progressBar}
                showEstimation={false}
                current={parseInt(currentEndDevices)}
                target={parseInt(discoveryTargetEndDevices)}
                warn={calculatePercentage(
                  parseInt(currentEndDevices),
                  parseInt(discoveryTargetEndDevices),
                )}
              />
              <div className="d-flex al-center gap-cs-xxs">
                <Message
                  content={m.currentEndDevices}
                  values={{
                    totalEndDevices: currentEndDevices,
                    threshold: discoveryTargetEndDevices,
                  }}
                  className="fs-s c-text-neutral-semilight"
                />
                <Tooltip content={<Message content={m.discoveryDevicesTooltip} />}>
                  <Icon icon={IconInfoCircle} small className="c-text-neutral-semilight" />
                </Tooltip>
              </div>
            </div>
            <div className="w-full">
              <ProgressBar
                className="mt-cs-l mb-cs-s"
                barClassName={style.progressBar}
                showEstimation={false}
                current={parseInt(currentGateways)}
                target={parseInt(discoveryTargetGateways)}
                warn={calculatePercentage(
                  parseInt(currentGateways),
                  parseInt(discoveryTargetGateways),
                )}
              />
              <div className="d-flex al-center gap-cs-xxs">
                <Message
                  content={m.currentGateways}
                  values={{
                    totalGateways: currentGateways,
                    threshold: discoveryTargetGateways,
                  }}
                  className="fs-s c-text-neutral-semilight"
                />
                <Tooltip content={<Message content={m.discoveryGatewaysTooltip} />}>
                  <Icon icon={IconInfoCircle} small className="c-text-neutral-semilight" />
                </Tooltip>
              </div>
            </div>
          </div>
          <Button.AnchorLink
            secondary
            className="w-full fs-m mt-cs-l"
            message={sharedMessages.upgradeNow}
            icon={IconBolt}
            href={upgradeUrl}
            target="_blank"
          />
        </>
      ) : (
        <>
          <ProgressBar
            className="mt-cs-l mb-cs-s"
            barClassName={style.progressBar}
            showEstimation={false}
            current={parseInt(currentEndDevices)}
            target={parseInt(packageThreshold)}
            warn={calculatePercentage(parseInt(currentEndDevices), parseInt(packageThreshold))}
          />
          <div className="d-flex al-center gap-cs-xxs">
            <Message
              content={m.currentTierEndDevices}
              values={{
                totalEndDevices: currentEndDevices,
                threshold: packageThreshold,
              }}
              className="fs-s c-text-neutral-semilight"
            />
            <Tooltip
              className="fs-m lh-m"
              style={{ lineHeight: '1.5' }}
              interactive
              content={
                <Message
                  content={m.tiersTooltip}
                  values={{
                    Link: msg => (
                      <Link primary to="/admin-panel/network-information">
                        {msg}
                      </Link>
                    ),
                  }}
                />
              }
            >
              <Icon icon={IconInfoCircle} small className="c-text-neutral-semilight" />
            </Tooltip>
          </div>
          {!isPlus && (
            <Button.AnchorLink
              secondary
              className="w-full fs-m mt-cs-l"
              message={sharedMessages.upgradeNow}
              icon={IconBolt}
              href={upgradeUrl}
              target="_blank"
            />
          )}
        </>
      )}
    </div>
  )
}

export default SubscriptionNudge
