// Copyright © 2024 The Things Industries B.V.

import React, { useMemo, useState } from 'react'
import { defineMessages } from 'react-intl'
import ReactApexChart from 'react-apexcharts'
import classnames from 'classnames'

import { APPLICATION, GATEWAY } from '@console/constants/entities'

import { IconArrowDown, IconArrowUp, IconPlus } from '@ttn-lw/components/icon'
import Panel, { PanelError } from '@ttn-lw/components/panel'
import Badge from '@ttn-lw/components/badge'
import Toggle from '@ttn-lw/components/panel/toggle'
import Button from '@ttn-lw/components/button'

import RequireRequest from '@ttn-lw/lib/components/require-request'
import Message from '@ttn-lw/lib/components/message'

import {
  getRandomSeries,
  getSeries,
  options,
} from '@console/containers/noc-metrics-panel/chart-utils.tti'

import PropTypes from '@ttn-lw/lib/prop-types'
import { selectApplicationRootPath, selectNocUrl } from '@ttn-lw/lib/selectors/env'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import style from './noc-metrics-panel.tti.styl'

const nocEntityPages = {
  [APPLICATION]: '/d/extended-ttsapplication/application-details',
  [GATEWAY]: '/d/extended-ttsgateway/gateway-details',
  DEVICE: '/d/extended-ttsapplication/application-details',
}

const m = defineMessages({
  networkStatusUnavailable: 'The {entity} network status is currently unavailable',
  noEntitiesTitle: 'No {entities}',
  noEntitiesSubtitle: 'Your {entities} will appear here.',
  addEntity: 'Add {entity}',
})

const getBadgeProps = (currentCount, pastCount) => {
  if (currentCount < pastCount) {
    return { status: 'error', endIcon: IconArrowDown }
  } else if (currentCount > pastCount) {
    return { status: 'success', endIcon: IconArrowUp }
  }
  return { status: 'info' }
}

const toggleOptions = [
  { label: '7 days', value: 7 },
  { label: '30 days', value: 30 },
]

const NocMetricsPanel = ({
  title,
  icon,
  fetchData,
  data,
  totalCount,
  showRandomValues,
  entity,
  entityPath,
}) => {
  const nocUrl = selectNocUrl() + nocEntityPages[entity]

  const appRoot = selectApplicationRootPath()
  const [activeToggle, setActiveToggle] = useState(7)

  const entityLowercase = entity.toLowerCase()

  const computedData = useMemo(
    () => (showRandomValues ? getRandomSeries(totalCount) : data),
    [data, showRandomValues, totalCount],
  )

  const currentCount = useMemo(
    () => computedData[computedData.length - 1]?.count ?? 0,
    [computedData],
  )
  const pastCount = useMemo(
    () => computedData[computedData.length - activeToggle - 1]?.count ?? 0,
    [computedData, activeToggle],
  )

  const formattedDifference = useMemo(() => {
    const difference = currentCount - pastCount
    return difference > 0 ? `+${difference}` : difference.toString()
  }, [currentCount, pastCount])

  const handleToggleChange = React.useCallback((_, value) => {
    setActiveToggle(value)
  }, [])

  const requestActions = []

  if (!showRandomValues) {
    requestActions.push(fetchData())
  }

  const areEntitiesActive = currentCount !== 0

  return (
    <Panel
      title={title}
      icon={icon}
      shortCutLinkTitle={
        areEntitiesActive || !entityPath
          ? sharedMessages.nocShortened
          : sharedMessages[`${entityLowercase}s`]
      }
      shortCutLinkPath={areEntitiesActive || !entityPath ? nocUrl : entityPath}
      shortCutLinkTarget={areEntitiesActive || !entityPath ? '_blank' : undefined}
      className={style.panel}
      compact
    >
      <RequireRequest
        spinnerProps={{ inline: true, center: true }}
        errorRenderFunction={() => (
          <PanelError>
            <Message
              className="c-text-neutral-light text-center fs-s"
              component="p"
              content={m.networkStatusUnavailable}
              values={{ entity: sharedMessages[entityLowercase].defaultMessage.toLowerCase() }}
            />
          </PanelError>
        )}
        requestAction={requestActions}
      >
        {areEntitiesActive ? (
          <>
            <div
              className={classnames(
                style.statusContainer,
                'd-flex',
                'j-between',
                'al-center',
                'mb-cs-m',
                'flex-wrap',
              )}
            >
              <div className="d-flex al-center gap-cs-xs">
                <span className="c-text-neutral-heavy fw-bold fs-xl4">{currentCount}</span>
                <span className="c-text-neutral-heavy fw-bold fs-xxl">/</span>
                <span className="c-text-neutral-heavy fw-bold fs-xxl">{totalCount}</span>
              </div>
              <Badge {...getBadgeProps(currentCount, pastCount)}>{formattedDifference}</Badge>
            </div>
            <ReactApexChart
              options={options}
              series={getSeries(computedData, activeToggle)}
              type="area"
              height={100}
            />
            <Toggle
              options={toggleOptions}
              active={activeToggle}
              onToggleChange={handleToggleChange}
              fullWidth
            />
          </>
        ) : (
          <div className={style.content}>
            <Message
              className="c-text-neutral-heavy fw-bold fs-l text-center"
              content={m.noEntitiesTitle}
              values={{
                entities: title.defaultMessage.toLowerCase(),
              }}
            />
            <Message
              className="c-text-neutral-light fs-m text-center mb-cs-l"
              content={m.noEntitiesSubtitle}
              values={{
                entities: title.defaultMessage.toLowerCase(),
              }}
            />
            {Boolean(entityPath) && (
              <Button.AnchorLink
                primary
                message={m.addEntity}
                messageValues={{
                  entity: sharedMessages[entityLowercase].defaultMessage.toLowerCase(),
                }}
                icon={IconPlus}
                href={`${appRoot}${entityPath}/add`}
              />
            )}
          </div>
        )}
      </RequireRequest>
    </Panel>
  )
}

NocMetricsPanel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string.isRequired,
      count: PropTypes.number,
    }),
  ).isRequired,
  entity: PropTypes.oneOf([APPLICATION, GATEWAY, 'DEVICE']).isRequired,
  entityPath: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  icon: PropTypes.icon.isRequired,
  showRandomValues: PropTypes.bool.isRequired,
  title: PropTypes.message.isRequired,
  totalCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

NocMetricsPanel.defaultProps = {
  entityPath: undefined,
}

export default NocMetricsPanel
