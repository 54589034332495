// Copyright © 2023 The Things Industries B.V.

import { handleActions } from 'redux-actions'

import {
  GET_NOC_CONFIGURATION_SUCCESS,
  GET_NOC_CONNECTED_GATEWAYS_SUCCESS,
  GET_NOC_ACTIVE_APPLICATIONS_SUCCESS,
  GET_NOC_ACTIVE_END_DEVICES_SUCCESS,
  GET_GATEWAY_PACKETS_PER_DATA_RATE_SUCCESS,
  GET_GATEWAY_PACKETS_PER_CHANNEL_SUCCESS,
  GET_APPLICATION_PACKETS_PER_DATA_RATE_SUCCESS,
  GET_APPLICATION_PACKETS_PER_CHANNEL_SUCCESS,
  GET_NOC_GATEWAY_UPTIME_SUCCESS,
  GET_END_DEVICE_PACKETS_PER_DATA_RATE_SUCCESS,
  GET_END_DEVICE_PACKETS_PER_CHANNEL_SUCCESS,
} from '@console/store/actions/network-operations-center.tti'

const defaultState = {
  configuration: {},
  connectedGateways: [],
  activeApplications: [],
  activeEndDevices: [],
  gatewayPacketsPerDataRate: {},
  gatewayPacketsPerChannel: {},
  applicationPacketsPerDataRate: {},
  applicationPacketsPerChannel: {},
  endDevicePacketsPerDataRate: {},
  endDevicePacketsPerChannel: {},
  uptime: [],
}

const formatPacketsPayload = data => {
  const result = {}
  data.forEach(item => {
    Object.keys(item.count).forEach(key => {
      const value = { time: item.time, count: item.count[key] }
      if (result[key]) result[key] = [...result[key], value]
      else result[key] = [value]
    })
  })

  return result
}

export default handleActions(
  {
    [GET_NOC_CONFIGURATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      configuration: payload.configuration,
    }),
    [GET_NOC_CONNECTED_GATEWAYS_SUCCESS]: (state, { payload }) => ({
      ...state,
      connectedGateways: payload,
    }),
    [GET_NOC_ACTIVE_APPLICATIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      activeApplications: payload,
    }),
    [GET_NOC_ACTIVE_END_DEVICES_SUCCESS]: (state, { payload }) => ({
      ...state,
      activeEndDevices: payload,
    }),
    [GET_GATEWAY_PACKETS_PER_DATA_RATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      gatewayPacketsPerDataRate: formatPacketsPayload(payload),
    }),
    [GET_GATEWAY_PACKETS_PER_CHANNEL_SUCCESS]: (state, { payload }) => ({
      ...state,
      gatewayPacketsPerChannel: formatPacketsPayload(payload),
    }),
    [GET_APPLICATION_PACKETS_PER_DATA_RATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      applicationPacketsPerDataRate: formatPacketsPayload(payload),
    }),
    [GET_APPLICATION_PACKETS_PER_CHANNEL_SUCCESS]: (state, { payload }) => ({
      ...state,
      applicationPacketsPerChannel: formatPacketsPayload(payload),
    }),
    [GET_END_DEVICE_PACKETS_PER_DATA_RATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      endDevicePacketsPerDataRate: formatPacketsPayload(payload),
    }),
    [GET_END_DEVICE_PACKETS_PER_CHANNEL_SUCCESS]: (state, { payload }) => ({
      ...state,
      endDevicePacketsPerChannel: formatPacketsPayload(payload),
    }),
    [GET_NOC_GATEWAY_UPTIME_SUCCESS]: (state, { payload }) => ({
      ...state,
      uptime: [...state.uptime, { [payload.gatewayId]: payload.uptime }],
    }),
  },
  defaultState,
)
