// Copyright © 2024 The Things Industries B.V.

import React from 'react'
import classNames from 'classnames'
import { FormattedNumber, defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import Button from '@ttn-lw/components/button'
import { IconBolt } from '@ttn-lw/components/icon'

import Message from '@ttn-lw/lib/components/message'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import { selectPluginTTSSandboxEnabled } from '@ttn-lw/lib/selectors/env'
import PropTypes from '@ttn-lw/lib/prop-types'

import { selectUserIsAdmin } from '@console/store/selectors/logout'

import style from './gateway-status-panel.styl'

const m = defineMessages({
  unlockGraph: 'Unlock uptime graph',
  noUptime: 'No uptime data available',
  onlyAdmins: 'Only admins have access to uptime',
})

const Uptime = ({ uptime, isNocEnabled, EmptyState }) => {
  // The redering of this section is based on the user's permissions and the availability of the NOC.
  // Note the different setups:
  // non-admins on sandbox: show the upgrade button
  // nocAccess but not admin: show message saying that only admins can see it
  // nocAccess and admin: show value

  const isAdmin = useSelector(selectUserIsAdmin)
  const isSandboxEnabled = useSelector(selectPluginTTSSandboxEnabled)

  if ((!isNocEnabled && isAdmin) || (isSandboxEnabled && !isAdmin)) {
    return (
      <div className="mt-cs-l">
        <Message content={m.unlockGraph} component="div" className="fw-bold" />
        <Button.AnchorLink
          naked
          message={sharedMessages.upgradeNow}
          icon={IconBolt}
          href="https://www.thethingsindustries.com/stack/plans/"
          target="_blank"
          className={style.gtwStatusPanelUpgradeButton}
        />
      </div>
    )
  }

  if (!isAdmin) {
    return <Message content={m.onlyAdmins} className="mt-cs-l c-text-neutral-semilight" />
  }

  if (!uptime) {
    return <EmptyState title={sharedMessages.noData} message={m.noUptime} />
  }

  return (
    <>
      <div className={style.gtwStatusPanelUptimeBar}>
        <div
          className="c-bg-success-semilight h-full"
          style={{
            width: `${(uptime * 100).toFixed(2)}%`,
          }}
        />
        <div
          className="c-bg-error-semilight h-full"
          style={{
            width: `${(100 - uptime * 100).toFixed(2)}%`,
          }}
        />
      </div>
      <div
        className={classNames(style.gtwStatusPanelRoundTripTimeTag, {
          'c-text-success-normal': uptime >= 0.95,
          'c-text-warning-normal': uptime < 0.95 && uptime >= 0.9,
          'c-text-error-normal': uptime < 0.9,
        })}
      >
        <FormattedNumber style="percent" value={uptime} minimumFractionDigits={2} />
      </div>
    </>
  )
}

Uptime.propTypes = {
  EmptyState: PropTypes.func.isRequired,
  isNocEnabled: PropTypes.bool.isRequired,
  uptime: PropTypes.shape({}),
}

Uptime.defaultProps = {
  uptime: null,
}

export default Uptime
