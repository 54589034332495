// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import ReactApexChart from 'react-apexcharts'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { random } from 'lodash'

import PropTypes from '@ttn-lw/lib/prop-types'

import options from './chart-utils'

const HeatmapChart = ({ showRandomValues, data, height }) => {
  const chartRef = useRef(null)
  const [chartOptions, setChartOptions] = useState(options)
  const series = useMemo(() => {
    if (showRandomValues) {
      return new Array(random(4, 18)).fill(1).map(() => ({
        name: `${random(800, 999)}.${random(100, 999)}`,
        data: Array.from({ length: 50 }, () => ({
          x: Date.now() + random(0, 1000) * 1000,
          y: random(0, 100),
        })),
      }))
    }
    return data
  }, [data, showRandomValues])

  const handleResize = useCallback(() => {
    const yAxisLength = series.length
    if (yAxisLength > 0 && chartRef.current) {
      const xAxisLength = series[0].data.length
      const axisDiff = yAxisLength - xAxisLength
      // Formula that calculates the right padding based on axis difference and container width
      const newRightPadding = -((chartRef.current.offsetWidth * axisDiff) / (xAxisLength * 1.11))
      setChartOptions(prevOptions =>
        axisDiff > 0
          ? {
              ...prevOptions,
              grid: {
                padding: {
                  ...prevOptions.grid.padding,
                  right: newRightPadding,
                },
              },
            }
          : options,
      )
    }
  }, [series])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <div className="w-full" ref={chartRef}>
      <ReactApexChart options={chartOptions} series={series} type="heatmap" height={height} />
    </div>
  )
}

HeatmapChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  height: PropTypes.number,
  showRandomValues: PropTypes.bool.isRequired,
}

HeatmapChart.defaultProps = {
  height: 350,
}

export default HeatmapChart
